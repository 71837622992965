import React from 'react';
import Data from './Data.js';
import './Postview.css';


const Postview=()=> {
  return (
    <div className="site-container">
      <Data/>
    </div>
  );
}

export default Postview;
